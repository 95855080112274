<template>
  <div class="content">
    <a-affix :offset-top="0">
      <Header title="我的订单" />
    </a-affix>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list"
      >
        <div class="item" v-for="(v, k) in list" :key="k" @click="$router.push({name: 'customer-order', params: {id: v.id}})">
          <div class="top">
            <span v-if="store">{{ store.title }}</span>
            <span v-else>五味楼酒店</span>
            <span class="state">{{
              [
                "吧台预定",
                "客户经理预定",
                "待支付",
                "预订成功",
                "开餐",
                "退订",
                "不来",
                "结算完成",
              ][v.orderStatus]
            }}</span>
          </div>
          <div class="info-box">
            <div class="img">
              <img src="@/assets/customer/zoom.jpg" alt="五味楼" />
            </div>
            <div class="info">
              <div class="time">
                {{ $filters.formatDate(v.bookTime, "YYYY-MM-DD HH:mm") }}到店
              </div>
              <div class="name">{{ v.tableName }}</div>
              <div class="combo">
                {{ v.comboPrice }}元/人 * {{ v.peopleCount }}人
              </div>
            </div>
          </div>
          <div class="price-action">
            <div class="remark">
              <span v-if="v.customerRemark">备注：{{ v.customerRemark }}</span>
            </div>
            <div class="price">
              ¥<span>{{ v.payAmount }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import Header from './Header'
import { List, PullRefresh } from 'vant'
import 'vant/es/list/style'
import 'vant/es/pull-refresh/style'
import { OrderClass } from '@/apis/order'
import defaultConfig from '@/config/default.config'

const order = new OrderClass()
export default defineComponent({
  components: {
    Header,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh
  },
  setup () {
    const queryParams = ref({
      Limit: 20
    })
    const paginate = ref({
      total: 0,
      page: 1
    })
    const store = ref()
    const list = ref([])
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)

    const onLoad = () => {
      console.log('加载', paginate.value.total, paginate.value.page)
      if (
        paginate.value.page * queryParams.value.Limit >= paginate.value.total ||
        paginate.value.total <= queryParams.value.Limit
      ) {
        finished.value = true
      } else {
        getDataList(++paginate.value.page)
      }
    }

    const getDataList = (page) => {
      loading.value = true
      if (page === 1) {
        list.value = []
      }

      paginate.value.page = page
      const q = { ...queryParams.value }

      q.Offset = (page - 1) * q.Limit
      order.personalGets(q).then((resp) => {
        list.value.push(...resp.data)

        paginate.value.total = resp.total

        loading.value = false
        refreshing.value = false

        if (page >= resp.total) {
          finished.value = true
        } else {
          finished.value = false
        }
      })
    }

    const onRefresh = () => {
      refreshing.value = true
      getDataList(1)
    }

    getDataList(1)

    return {
      queryParams,
      paginate,
      list,
      loading,
      finished,
      refreshing,
      store,
      onLoad,
      onRefresh
    }
  },

  created () {
    this.getStoreInfo()
  },
  methods: {
    async getStoreInfo () {
      const store = this.$store.state.user.storeInfo.id
        ? this.$store.state.user.storeInfo
        : await this.$store.dispatch('GetStoreInfo', defaultConfig.store_id)

      this.store = store
    }
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #ffffff;
}

.list {
  padding: 0.3rem;
  .item {
    border-radius: 0.1rem;
    box-shadow: 0 0 0.25rem rgba(150, 150, 150, 0.3);
    margin-bottom: 0.3rem;
    background: #ffffff;
    padding: 0.3rem 0.18rem;
    .top {
      font-size: 0.28rem;
      color: #333333;
      line-height: 0.44rem;
      display: flex;
      justify-content: space-between;
      .state {
        font-size: 0.24rem;
      }
    }

    .info-box {
      padding-top: 0.2rem;
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #ebebeb;
      position: relative;
      padding-left: 1.98rem;
      .img {
        width: 1.78rem;
        height: 1.15rem;
        border-radius: 0.08rem;
        overflow: hidden;
        position: absolute;
        top: 0.2rem;
        left: 0;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .info {
        font-size: 0.24rem;
        position: relative;
        height: 1.15rem;
        .name {
          line-height: 0.6rem;
        }
        .time {
          line-height: 0.44rem;
        }
        .combo {
          position: absolute;
          bottom: 0;
          right: 0;
          line-height: 0.6rem;
          line-height: 0.4rem;
        }
      }
    }

    .price-action {
      padding-top: 0.28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price {
        font-size: 0.36rem;
      }
      .remark {
        font-size: 0.24rem;
        width: 4rem;
      }
    }
  }
}
</style>
